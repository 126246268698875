import {AfterViewInit, Component, ViewChild} from '@angular/core';
// import { Plugins } from '@capacitor/core';
// const { SplashScreen } = Plugins;
import {AlertController, IonMenu, MenuController, Platform} from '@ionic/angular';
import { LocalStorageService } from './core/local-storage/local-storage.service';
import { Router } from '@angular/router';
import { AuthStoreService } from './core/auth/auth.service';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import * as moment from 'moment';
import { FormsStoreService } from './core/forms/forms.service';
import { SplashScreen } from '@capacitor/splash-screen';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  activeUserRoleId: string;
  activeAPI = '0'; // 0 = Production | 1 = Staging

  @ViewChild('mainMenu') mainMenu: IonMenu;

  constructor(
    private platform: Platform,
    private localStorageService: LocalStorageService,
    private router: Router, // TODO review this to see if needed -> private statusBar: StatusBar
    private menu: MenuController,
    private authStoreService: AuthStoreService,
    private oneSignal: OneSignal,
    private formsStoreService: FormsStoreService,
    public alertController: AlertController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    // SplashScreen.show({
    //   showDuration: 3000,
    //   autoHide: false,
    // });
    this.initSplashScreenConfig();
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      // SplashScreen.hide();
      this.hideSplashScreen();
      this.oneSignal.startInit(
        '922fa6fc-2780-4496-88b2-1f3fe02fb1e5',
        '922728282249'
      );

      this.oneSignal.inFocusDisplaying(
        this.oneSignal.OSInFocusDisplayOption.InAppAlert
      );

      this.oneSignal.handleNotificationReceived().subscribe(() => {
        // do something when notification is received
        console.log('notified');
      });

      this.oneSignal.handleNotificationOpened().subscribe(() => {
        // do something when a notification is opened
        console.log('notification opened');
      });

      this.oneSignal.endInit();

      this.menu.enable(true, 'custom');
      const jwt = this.localStorageService.getItem('jwt');
      if (jwt && jwt !== '') {
        this.router.navigate(['/dashboard'], {
          state: {
            resetWeekIndex: true,
          },
        });
      } else {
        this.router.navigate(['']);
      }
    });

    this.activeUserRoleId = this.localStorageService.getItem('role_id');
    this.localStorageService.getItem('activeAPI') ? this.activeAPI = this.localStorageService.getItem('activeAPI') : this.activeAPI = '0';
  }

  async initSplashScreenConfig() {
    await SplashScreen.show({
      showDuration: 2000,
      autoHide: true
    });
  }

  async hideSplashScreen() {
    await SplashScreen.hide();
  }

  ngAfterViewInit() {
    this.mainMenu.ionWillOpen.subscribe(() => this.activeUserRoleId = this.localStorageService.getItem('role_id'));
  }

  startScreening() {
    const userId = this.localStorageService.getItem('id');
    const currentDate = moment().format('YYYY-MM-DD hh:mm:ss');
    this.formsStoreService.initializeForm(currentDate, userId, '20');
    this.router.navigate(['/screening/questions']);
    this.menu.close('custom');
  }

  selectMenuItem(route: string) {
    this.menu.close('custom');
    if (route === 'logout') {
      return this.authStoreService.logout();
    }
    this.router.navigate([`/${route}`]);
  }

  async presentAPISelection() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'API Selection',
      subHeader: 'Please select an API environment.',
      inputs: [
        {
          name: 'Production',
          type: 'radio',
          label: 'Production API',
          value: 'Production',
          handler: () => {
            this.localStorageService.setItem('activeAPI', '0');
          },
          checked: this.activeAPI === '0'
        },
        {
          name: 'Staging',
          type: 'radio',
          label: 'Staging API',
          value: 'Staging',
          handler: () => {
            this.localStorageService.setItem('activeAPI', '1');
          },
          checked: this.activeAPI === '1'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Confirm',
          handler: () => {
            if (this.activeAPI !== this.localStorageService.getItem('activeAPI')) {
              this.activeAPI = this.localStorageService.getItem('activeAPI');
              alert.dismiss();
              this.menu.close('custom');
              this.authStoreService.logout();
            }
          }
        }
      ]
    });

    await alert.present();
  }
}
