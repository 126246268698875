import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { EquipmentManagerComponent } from './components/equipment-manager/equipment-manager.component';
import { TowManagerComponent } from './components/tow-manager/tow-manager.component';
import { MaterialManagerComponent } from './components/material-manager/material-manager.component';
import {NotesManagerComponent} from './components/notes-manager/notes-manager.component';
import {AddEditNoteComponent} from './components/notes-manager/add-edit-note/add-edit-note.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [AppComponent, EquipmentManagerComponent, TowManagerComponent, MaterialManagerComponent, NotesManagerComponent, AddEditNoteComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
  ],
  providers: [
    OneSignal,
    AppVersion,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
